
import { mapGetters, mapMutations, mapActions } from 'vuex'
import bodyClasses from "@/mixins/bodyClasses";
import formValidation from "@/mixins/formValidation";
import filters from "@/mixins/filters";
import callback from "@/mixins/callback";

// import metrika from "@/mixins/metrika";

export default {
	mixins: [bodyClasses, formValidation, filters, callback],
	data() {
		return {
			localModal: false,

			headerActive: false,
			nav: [
				{
					title: 'банки-партнеры',
					link: '/banks'
				},
				{
					title: 'об автоцентре',
					link: '/about'
				}
			],
		}
	},

	computed: {
		// theme() {
		// 	return this.$route.fullPath === '/' ? 'white' : 'black'
		// },
		...mapGetters({
			modalMenu: 'modal/modal-menu/modalMenu',
			modalMarks: 'modal/modal-marks/modalMarks',
			modalSearch: 'modal/modal-search/modalSearch',
			allMarks: 'marks/marks/allMarks',
			likesArray: 'favorite/favorite/likesArray',
			settings: 'settings/settings/settings',
			componentCatalog: 'filters/filters/componentCatalog',
			marks: 'marks/marks/allMarks',
			backButton: 'header/header/backButton',
			mobileSearch: 'modal/modal-search/mobileSearch',
			popularMarks: 'marks/marks/popularMarks',
			settings: 'settings/settings/settings',
			isNight: "settings/settings/isNight",
		}),
		currentMark() {
			return this.marks.filter(item => this.$route.params.mark === item.slug)[0]
		},
		showHeaderCrumbs() {
			if (this.$device.isMobile) {
				if (this.$route.params.car) {
					return this.componentCatalog === 'car';
				} else {
					return false
				}
			} else {
				return false
			}
		},
		routefullPath() {
			return this.$route.fullPath
		}
	},



	methods: {

		...mapMutations({
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks',
			setModalSearch: 'modal/modal-search/setModalSearch',
			setMobileSearch: 'modal/modal-search/setMobileSearch',
			setFocusShowFixes: 'car/car/SET_FOCUS_SHOW_FIXED'

		}),
		...mapActions({
			openModal: 'modal/modal-main/openModal',
			sendForm: 'form/form/sendForm'

		}),
		// async callback() {
		// 	let payload = {
		// 		modal_data: { title: 'Обратная связь', sub_title: 'Мы работаем с 9:00 до 21:00.', text: 'Оставьте заявку, и мы свяжемся с Вами в рабочее время.' },
		// 		modal_component: 'modal-timer',
		// 		modal_title: 'ЗАЯВКА НА АВТОКРЕДИТ',
		// 		modal_sub_title: {}
		// 	}
		// 	this.openModal(payload)
		// 	// this.callbackButtonClick()
		// },
		burgerClick() {
			this.setModalMenu(!this.modalMenu)
			this.setModalMarks(false)
			this.setModalSearch(false)
		},
		navMarkClick() {
			this.setModalMenu(false)
			this.setModalMarks(false)
			this.setModalSearch(false)
		},
		allMarksClick() {
			this.setModalMarks(!this.modalMarks)
			this.setModalMenu(false)
			this.setModalSearch(false)
		},
		...mapActions({
			getLikes: 'favorite/favorite/getLikes',
		}),


		scrollHandler(e) {
			// console.log(headerMiddle.getBoundingClientRect().height,
			// 	slideContentBlock.getBoundingClientRect()
			// )

			// if (headerMiddle.getBoundingClientRect().height < slideContentBlock.getBoundingClientRect().top) {
			// 	this.headerActive = true
			// }
			// if (headerMiddle.getBoundingClientRect().height > slideContentBlock.getBoundingClientRect().top
			// 	&& headerMiddle.getBoundingClientRect().height < slideContentBlock.getBoundingClientRect().height + slideContentBlock.getBoundingClientRect().top) {
			// 	this.headerActive = false
			// }
			// if (headerMiddle.getBoundingClientRect().height > slideContentBlock.getBoundingClientRect().height + slideContentBlock.getBoundingClientRect().top) {
			// 	this.headerActive = true
			// }


			// if (headerMiddle.getBoundingClientRect().height < slideContentBlock.getBoundingClientRect().top) {
			// 	this.headerActive = true
			// }
			if (this.$route.path === '/')
				if (window.scrollY) {
					this.headerActive = true
				} else {
					this.headerActive = false
				}


		},

		initHeaderAction() {
			if (!this.$device.isMobile)
				setTimeout(() => {
					// console.log(this.$route.path)
					// const headerMiddle = this.$refs.headerMiddle
					// const slideContentBlock = document.getElementsByClassName('header-changer')[0]
					// window.removeEventListener('scroll', (e) => this.scrollHandler(headerMiddle, slideContentBlock, e))

					// if (slideContentBlock) {
					// 	window.addEventListener('scroll', (e) => this.scrollHandler(headerMiddle, slideContentBlock, e))
					// }
					window.removeEventListener('scroll', (e) => this.scrollHandler(e))

					// if (slideContentBlock) {

					window.addEventListener('scroll', (e) => this.scrollHandler(e))
					// }



				})


		},

		onFocus() {
			this.setFocusShowFixes(false)
		},
		onFocusOut() {
			setTimeout(() => {
				this.setFocusShowFixes(true)
			}, 1000)
		},
		checkForm() {
			if (this.form.name.value.length < 2) {
				this.form.name.valid = false
				return false
			}
			if (!this.form.phone.valid) {
				this.form.phone.valid = false
				return false
			}
			if (!this.form.agree) {
				this.error = 'agree'
				return false
			}
			return true;
		},
		async submitForm() {
			if (this.checkForm()) {
				let formData = {
					chosen_car: {},
					type: 'callback',
					client_name: this.form.name.value,
					client_phone: this.form.phone.value,
				}
				// utm
				if (localStorage.utm_source) {
					formData.utm_source = localStorage.utm_source
				}
				if (localStorage.utm_medium) {
					formData.utm_medium = localStorage.utm_medium
				}
				if (localStorage.utm_campaign) {
					formData.utm_campaign = localStorage.utm_campaign
				}
				if (localStorage.utm_term) {
					formData.utm_term = localStorage.utm_term
				}
				if (localStorage.utm_content) {
					formData.utm_content = localStorage.utm_content
				}
				// if (this.offer.dealerModal) {
				//   formData.comment = this.offer.title
				// } else {
				//   formData.external_id = this.offer.external_id
				// }
				// await this.setModalCount(1)
				await this.sendForm(formData)
				// await this.closeModal()

			}
		},
		changeHeaderSwitcher() {
			if (this.$route.path === '/' && !this.$device.isMobile) {
				this.headerActive = false
			}
			else {
				this.headerActive = true
			}
		}
	},
	mounted() {
		this.getLikes()
		// if (this.$route.path === '/') {
		// 	this.headerActive = false
		// }
		// else {
		// 	this.headerActive = true
		// }
		this.changeHeaderSwitcher()
		this.initHeaderAction()
	},
	watch: {
		'$route'(value) {
			if (value.path === '/' && !this.$device.isMobile) {
				this.headerActive = false
			} else {
				this.headerActive = true
			}
			// this.headerActive = false
			this.initHeaderAction()
			this.setModalMenu(false)
		}
	}

}
