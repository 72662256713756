
import { mapGetters } from "vuex";

export default {
	data() {
		return {

		}

	},
	computed: {
		...mapGetters({
			// isMoscow: 'isMoscow',
			settings: 'settings/settings/settings'
			// showComm: 'showComm',
		}),
		siteList() {
			return [
				{

					name: 'Автомобили с пробегом',
					link: '/cars',
					show: true

				},
				{
					name: 'Автокредит',
					link: '/credit',
					show: true
				},
				{
					name: 'Trade-In',
					link: '/exchange',
					show: true
				},
				{
					name: 'Выкуп',
					link: '/buyout',
					show: true
				},
				{
					name: 'Рассрочка',
					link: '/installments',
					show: true
				},
        {
					name: 'Банки-партнеры',
					link: '/banks',
					show: true
				},
				{
					name: 'Контакты',
					link: '/contacts',
					show: true
				},
				{
					name: 'О компании',
					link: '/about',
					show: true
				},
			]
		},
		siteList_sub() {
			return [
				{
					name: 'Контакты',
					link: '/contacts',
					show: true
				},
				{
					name: 'Об автосалоне',
					link: '/about',
					show: true
				}

			]
		}
	}
}
